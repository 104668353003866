<template>
    <!-- 主体部分 -->
    <div class="mains">
        <!--左边菜单栏部分 -->
        <div class="leftMenu">
            <el-menu class="el-menu-vertical-demo" text-color="#262626" active-text-color="#451245" unique-opened>
                <div class="logo">
                    <img src="../../assets/imgs/logo.png" alt="">
                </div>
                <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
                    <template slot="title">
                        <span class="icons">
							<img :src="'https://education.ztxinde.com/' + item.icon">
						</span>
                        <span slot="title">{{item.auth_name}}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item :index="'/' + subItem.auth_route" v-for="subItem in item.children"
                            :key="subItem.id" @click="jump(subItem.auth_route)">{{subItem.auth_name}}</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
        </div>
        <!-- 右边内容显示部分 -->
        <div class="rightDiv">
            <!-- 账号头像显示部分 -->
            <div class="head_img">
                <div class="outDiv" @click="handleOut">
                    <img src="../../assets/imgs/out.png" alt="">
                </div>
                <div class="nameDiv">{{uname}}</div>
                <!-- <div class="imgDiv"></div> -->
            </div>
            <!-- 模块信息显示部分 -->
            <div class="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { loginOutApi } from '@/api'
export default {
    data () {
        return {
            // 菜单数据
            menuList: [],
            //用户名
            uname: '',
        }
    },
    computed: {
        ...mapState(['LeftList', 'UserName'])
    },
    created () {
		
        let token = sessionStorage.getItem('Token');
        if (token == '') {
            // window.location.href = '/login'
            this.$router.push({ path: '/login' })
        }
		this.menuList = this.LeftList;        
        this.uname = this.UserName;
    },
    methods: {
        // 点击按钮退出
        handleOut () {
            let id = sessionStorage.getItem('id')
            // console.log(quit)
            loginOutApi({
                id: id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    sessionStorage.clear();
                    window.location.reload();
                    this.$router.push({ path: '/login' });
                    this.$message.success('退出成功！')
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

// 主体部分
.mains {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    // 左边菜单部分
    .leftMenu {
        width: 16%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        // 菜单部分
        .el-menu {
            width: 100%;
            height: 100%;
            background: white;
            font-size: 14px;
            text-align: left;
            // 公司图标
            .logo {
                width: 100%;
                height: 92px;
                text-align: center;
                img {
                    width: 100%;
                    height: 80%;
                    margin-top: 14px;
                }
            }
            .el-submenu {
				
                // 图标部分
                .icons {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    img {
                        width: 20px;
                        height: 20px;
						vertical-align: baseline !important;
                    }
                }
                //导航菜单大板块的对齐
                ::v-deep.el-submenu__title {
                    padding-left: 30px;
                }
                // 小模块展开
                .el-menu-item-group {
                    text-indent: 20px;
                }
                ::v-deep.el-menu-item-group__title {
                    padding: 0px;
                }
            }
            .el-submenu.is-active {
                .el-menu-item.is-active {
                    border-right: 4px solid #2479dd;
                    background: #e5e5e5;
                }
            }
        }
    }
    // 右边内容显示部分
    .rightDiv {
        width: 86%;
        height: 100%;
        background: #e5e5e5;
        overflow-x: hidden;
        overflow-y: scroll;
        // 账号信息，头像显示部分
        .head_img {
            width: 100%;
            height: 92px;
            background: #fff;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .outDiv {
                width: 20px;
                height: 20px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .nameDiv {
                margin: 0 36px 0 20px;
                font-size: 30px;
                font-weight: 600;
            }
            // .imgDiv {
            //     width: 52px;
            //     height: 52px;
            //     margin-right: 20px;
            //     border-radius: 50%;
            //     background: black;
            // }
        }
        .content {
            width: 96%;
            margin: 34px auto;
            min-height: 200px;
            border-radius: 8px;
            overflow: hidden;
        }
    }
}
</style>